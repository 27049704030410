import { AuthMethod } from '@hubcms/domain-auth';
import { GtmCode } from '@hubcms/domain-tracking';
import { EPaperLinkImage } from '@hubcms/domain-navigation';

import ePaperImage from './e-paper.png';

export { de as dateFnsLocale } from 'date-fns/locale/de';
export const locale = 'de-LU';
export const timeZone = 'Europe/Luxembourg';
export const archiveTitle = 'Archiv';
export const gtmCodes: GtmCode[] = [{ code: 'GTM-MJWKBXZ', isParent: true }, { code: 'GTM-W62NBV5' }];
export const brandCode = 'lw';
export const adunitBrandCode = 'wort';
export const b2bUrlBrandCode = 'wort';
export const b2bDMPMetaTagBrandCode = 'stp';
export const footerLogo = 'variations.brand-name-white';
export const headerDateFormat = 'EEEE, dd. MMMM yyyy';
export const isHeroBlockMailIconInverted = true;
export const authorPrefix = 'autoren/';
export const searchPrefix = 'suche/';
export const tagsPrefix = 'tag/';
export const logoSlug = 'brand-square-icon-white';
export const authMethod: AuthMethod = 'oauth';
export const weatherSectionPage = '/wetter/';
export const cimIdentifier = 'd1LlRM8_0cf9ZF4B7_Qds7e_.KmwkBgLpxdEFErFHv7.J7';
export const cimLanguage = 'DE';
export const buttonAppearanceMapper = {
  primary: 'primary',
  secondary: 'secondary',
  outline: 'tertiary',
  plain: 'secondary',
};
export const tickarooClientId = '64772af30bfac400a065fb8b';
export const ePaperLinkLabel = 'E-Paper';
export const ePaperLinkHref = 'https://digitalpaper.wort.lu/';
export const ePaperLinkImage: EPaperLinkImage = {
  // Next.js and Storybook handle static image data differently. For Next.js, assign data.src. For Storybook, use fallback.
  src: ePaperImage.src ?? ePaperImage,
  height: 26,
  width: 36,
};
export const domain = 'wort.lu';
export const enablePersonalizedArticleList = true;
export const subscriptionButtonAppearance = 'tertiary';
export const enableGrapeshot = true;
export const heroBlockIntroProps = {
  size: 'lg',
  weight: 'strong',
};
export const imageCaptionProps = {
  font: 'alt',
  size: 'sm',
};
export const articleLayoutVersion = 'v2'; // articleV1 param
export const enableMySection = true;
export const isAppTopicsMenuEnabled = false;
